import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Chart } from "react-google-charts";

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 13;

export class AdmReports extends Component {
    static displayName = AdmReports.name;

    static topMember = 0;
    static topCountry = 0;
    static topVessel = 0;
    static topCategory = 0;
    static topSupplier = 0;
    static topProduct = 0;

    static topChart = false;

    static fltCountry = "";
    static fltVessel = "";
    static fltCategory = "";
    static fltSupplier = "";

    static fltOrder = "1";

    static totalPrice = 0;
    static totalQuantity = 0;

    static frmGrType = "Bar";

    async handleFilter(event) {
        let val = event.currentTarget.value;

        if (val === "10")
            AdmReports.fltOrder = "1";
        else if (val === "20")
            AdmReports.fltOrder = "2";

        this.setState({ portrdata: this.portdata});
    }

    constructor(props) {
        super(props);
        this.state = {
            portdata: [], countries: [], vessels: [], categories: [], suppliers: [],
            loading: true, modal: false, page: 0, grChart: "Bar"
        };
        this.handleApply = this.handleApply.bind(this);
        this.handleChart = this.handleChart.bind(this);
        this.handleGrChart = this.handleGrChart.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderChart(items, hGrChart) {
        if (items.length > 7) items = items.slice(0, 7);
        var chead = [];
        var all = false;
        var comb = "";
        if (AdmReports.topSupplier) comb = comb.concat("Supplier ");
        if (AdmReports.topCategory) comb = comb.concat("Category ");
        if (AdmReports.topVessel) comb = comb.concat("Vessel ");
        if (AdmReports.topCountry) comb = comb.concat("Country ");
        if (comb === "") all = true;
        chead.push(comb);
        chead.push("Price%");
        chead.push("Quantity%");

        var data = [];
        data.push(chead);
        items.forEach((item) => {
            var row = [];
            var title = "";
            if (AdmReports.topVessel) title = title.concat(item.vessel + "\n");
            if (AdmReports.topCategory) title = title.concat(item.category + "\n");
            if (AdmReports.topSupplier) title = title.concat(item.supplierName + "\n");
            if (AdmReports.topCountry) title = title.concat(item.country + "\n");
            if (all) title = "ALL";            
            row.push(title);
            row.push(Math.round((Number.parseFloat(item.sumPrice) / AdmReports.totalPrice * 100)*100)/100);
            row.push(Math.round((Number.parseFloat(item.sumQua) / AdmReports.totalQuantity * 100)*100)/100);
            data.push(row);
        });

        return (
            <div>
                <br />
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="Bar" > 
                <b>Bar</b></button>
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="BarChart" >  
                <b>BarChart</b></button>
                <button class="btn btn-outline-secondary" onClick={hGrChart} value="PieChart" > 
                <b>PieChart</b></button>
                <br />
                {AdmReports.frmGrType} <br />
                <div>
                    <Chart
                        chartType={AdmReports.frmGrType }
                        data={data}
                        width="100%"
                        height="500px"
                        options={{ legendToggle: true, is3D: true }}
                    />
                </div>
            </div>
        );
    }

    static renderopprodrefTable(items, hFilter) {

        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            {AdmReports.topMember ? <th>Member</th> : null }
                            {AdmReports.topCountry ? <th>Country</th> : null} 
                            {AdmReports.topVessel ? <th>Vessel</th> : null}
                            {AdmReports.topCategory ? <th>Category</th> : null}
                            {AdmReports.topSupplier ? <th>Supplier</th> : null}
                            <th style={{ "textAlign": "right" }}>
                                <button className={(AdmReports.fltOrder == "1")
                                ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="10"
                                ><b>Quantity</b></button></th>
                            <th style={{ "textAlign": "right" }}> Quantity%</th>
                            <th style={{ "textAlign": "right" }}> <button className={(AdmReports.fltOrder == "2")
                                ? "btn btn-secondary" : "btn btn-outline-secondary"}
                                onClick={hFilter} value="20"
                            ><b>Price</b></button></th>
                            <th style={{ "textAlign": "right" }}> Price% </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) =>
                            <tr key={item.key}>
                                {AdmReports.topMember ? <td>{item.memberName}</td> : null }
                                {AdmReports.topCountry ? <td>{item.country}</td> : null}
                                {AdmReports.topVessel ? <td>{item.vessel}</td> : null}
                                {AdmReports.topCategory ? <td>{item.category}</td> : null}
                                {AdmReports.topSupplier ? <td>{item.supplierName}</td> : null}
                                <td style={{ "textAlign": "right" }}> {Number.parseFloat(item.sumQua).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td style={{ "textAlign": "right" }}> {(Number.parseFloat(item.sumQua)
                                    / AdmReports.totalQuantity * 100).toFixed(2) + "%"}</td>
                                <td style={{ "textAlign": "right" }}> $ {Number.parseFloat(item.sumPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                <td style={{ "textAlign": "right" } }> {(Number.parseFloat(item.sumPrice)
                                    / AdmReports.totalPrice * 100).toFixed(2) + "%"}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    // grouping based on Port, Vessel, Category and Supplier
    static goReduce(items, topCountry, topVessel, topCategory, topSupplier) {
        var newitems = items.reduce((acc, curr) => {
            const found = acc.find
                (item =>
                    (this.topMember ? (item.memberId === curr.memberId) : true) &&
                    (topCountry ? (item.country === curr.country) : true) &&
                    (topVessel ? (item.vessel === curr.vessel) : true) &&
                    (topCategory ? (item.category === curr.category) : true) &&
                    (topSupplier ? (item.supplier === curr.supplier) : true)
                );
            if (!found) {
                curr.sumQua = curr.quantity;
                curr.sumPrice = curr.price;
                acc.push(curr);
            } else {
                var prevQua = parseFloat(found.sumQua);
                var prevPri = parseFloat(found.sumPrice);
                found.sumQua = prevQua + parseFloat(curr.quantity);
                found.sumPrice = prevPri + parseFloat(curr.price);
            }
            return acc;
        }, []);
        return newitems;
    }
    
    render() {
        var opItems = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        var sorted = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading)
        {
            sorted = [...this.state.portdata];
            sorted = AdmReports.goReduce(sorted, AdmReports.topCountry, AdmReports.topVessel,
                AdmReports.topCategory, AdmReports.topSupplier);

            console.log(sorted);
            if (AdmReports.fltOrder === "1")
            sorted = sorted.sort(
                (a, b) => Number.parseFloat(b.sumQua) - Number.parseFloat(a.sumQua));
            else
            sorted = sorted.sort(
                    (a, b) => Number.parseFloat(b.sumPrice) - Number.parseFloat(a.sumPrice));
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                opItems = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                opItems = sorted;
            }
        }


        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : (AdmReports.topChart) ? AdmReports.renderChart(opItems, this.handleGrChart) :
            AdmReports.renderopprodrefTable(opItems, this.handleFilter,
                this.handleModal, this.handleDelete);
        if ( (!this.state.loading) && (pages >= 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 border border-dark">
                            <Form>
                                <div class="row" style={{ "paddingBottom": "10px" }}>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topMember) ? "info" : "secondary"} style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topMember = !AdmReports.topMember; this.setState({ page: 0 }) }} >
                                            Member
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topCountry)?"info":"secondary" } style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topCountry = !AdmReports.topCountry; this.setState({ page: 0 }) }} > 
                                            Country
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topVessel) ? "info" : "secondary"} style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topVessel = !AdmReports.topVessel; this.setState({ page: 0 }) }} > 
                                            Vessel
                                        </Button>  
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topCategory) ? "info" : "secondary"} style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topCategory = !AdmReports.topCategory; this.setState({ page: 0 }) }} > 
                                            Category
                                        </Button>  
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topSupplier) ? "info" : "secondary"} style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topSupplier = !AdmReports.topSupplier; this.setState({ page: 0 }) }} > 
                                            Supplier
                                        </Button>
                                    </div>
                                    <div class="col-lg-2">
                                        <Button color={(AdmReports.topProduct) ? "info" : "secondary"} style={{ "width": "100%", "margin-top": "7px" }}
                                            onClick={() => { AdmReports.topProduct = !AdmReports.topProduct; this.setState({ page: 0 }) }} >
                                            Products
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div class="row" style={{ "paddingTop": "10px" }}>
                        <div class="col-lg-12" >
                            <div class="row">
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        id="FltMember">
                                        <option value="">all</option>
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                           onChange={(e) => AdmReports.fltCountry = e.target.value}
                                           id="FltCountry">
                                        <option value="">all</option>
                                        {this.state.countries.map((country) =>
                                        <option value={country.country}>{country.country}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => AdmReports.fltVessel = e.target.value}
                                        id="FltVessel">
                                        <option value="">all</option>
                                        {this.state.vessels.map((ves) =>
                                            <option value={ves.vesCurrentName}>{ves.vesCurrentName}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => AdmReports.fltCategory = e.target.value}
                                        id="FltCategory">
                                        <option value="">all</option>
                                        {this.state.categories.map((cat) =>
                                            <option value={cat}>{cat}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        onChange={(e) => AdmReports.fltSupplier = e.target.value}
                                        id="FltSupplier">
                                        <option value="">all</option>
                                        {this.state.suppliers.map((sup) =>
                                            <option value={sup.osuSupplier}>{sup.osuOperName}</option>)}
                                    </select>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-select"
                                        id="FltMember">
                                        <option value="">all</option>
                                    </select>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="row" style={{ "paddingTop": "10px" }}>
                        <div class="col-lg-1">
                            <label style={{ "paddingTop": "5px" }} >From:</label>
                        </div>
                        <div class="col-lg-3">
                            <Input id="DateFrom" type="date" valid />
                        </div>

                        <div class="col-lg-1">
                            <label style={{ "paddingTop": "5px" }} >To:</label>
                        </div>
                        <div class="col-lg-3">
                            <Input id="DateTo" type="date" valid />
                        </div>
                        <div class="col-lg-2">
                            <Button style={{ "width": "50%" }} onClick={this.handleApply}>Apply</Button>
                            {!AdmReports.topChart}
                        </div>
                            <div class="col-lg-2">
                            <Button color={(AdmReports.topChart) ? "info" : "secondary"} style={{ "width": "50%" }} onClick={this.handleChart}>Chart</Button>
                        </div>

                    </div>
                </div>

                {contents1}

                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>

            </div>
        );
    }

    async handleChart(event) {
        AdmReports.topChart = !AdmReports.topChart;
        this.setState({ page: 0 });
    }

    async handleGrChart(event) {
        AdmReports.frmGrType = event.currentTarget.value;
        this.setState({ page: 0 });
    }
        

    async handleApply(event) {
        const data = await AdmReports.dataGet();
        const dbportdata = data.portdata;
        const filtered = dbportdata.filter(function (item) {
            return (
                (AdmReports.fltCountry !== "") ? item.country === AdmReports.fltCountry : true &&
                    (AdmReports.fltVessel !== "") ? item.vessel === AdmReports.fltVessel : true &&
                        (AdmReports.fltCategory !== "") ? item.category === AdmReports.fltCategoty : true &&
                            (AdmReports.fltSupplier !== "") ? item.supplier == AdmReports.fltSupplier : true
            );
        });
        
        this.setState({ portdata: filtered });
    }

    static async dataGet() {
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/Reports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbportdata = await response.json();
        // initialize sums
        AdmReports.totalPrice = 0;
        AdmReports.totalQuantity = 0;
        dbportdata.forEach(item => {
            item.sumQua = item.quantity;
            AdmReports.totalQuantity += Number.parseFloat(item.quantity);
            item.sumPrice = item.price;
            AdmReports.totalPrice += Number.parseFloat(item.price);
        });
       

        // get Ports
        // var dbports = dbportdata.reduce((acc, curr) => {
        //    if (!(acc.find(item => item.port === curr.port)))
        //        acc.push(curr);
        //    return acc;
        // }, []);

        //get Countries
        var dbcountries = dbportdata.reduce((acc, curr) => {
            if (!(acc.find(item => item.country === curr.country)))
                acc.push(curr);
            return acc;
        }, []);


        // get Vessels
        const response3 = await fetch('mar1/Vessels', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvesselstemp = await response3.json();
        const dbvessels = dbvesselstemp.sort(
            (a, b) => a.vesCurrentName.localeCompare(b.vesCurrentName));

        // get Categories
        const response4 = await fetch('mar1/readCategories', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbcat = await response4.json();
        const dbcategories = [];
        dbcat.map(cat => {
            if (dbcategories.indexOf(cat.pctMainType) === -1) {
                dbcategories.push(cat.pctMainType)
            }
        });
        console.log(dbcategories);

        // get Suppliers
        const response5 = await fetch('mar1/Suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response5.json();

        // reduce vessels


        return {
            portdata: dbportdata, countries: dbcountries,
            vessels: dbvessels, categories: dbcategories, suppliers: dbsuppliers
        };
    }

    async populateData() {
        const data = await AdmReports.dataGet();
        const dbportdata = data.portdata;
        const dbcountries = data.countries;
        const dbvessels = data.vessels;
        const dbcategories = data.categories;
        const dbsuppliers = data.suppliers;

        this.setState({
            portdata: dbportdata, countries: dbcountries, vessels: dbvessels,
            categories: dbcategories, suppliers: dbsuppliers,
            loading: false, modal: this.state.modal
        });
    }

}

