import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import Autosuggest from 'react-autosuggest';
import './react-autosuggest.css';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 8;
var portList = [];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : portList.filter(port =>
        port.name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);


export class AdmPriceFind extends Component {
    static displayName = AdmPriceFind.name;

    //lists
    static allproducts = [];
    static suppliers = [];

    //input data
    static frmQuantity = "";
    static frmPack = "Bulk";
    static frmProduct = null;

    // input hidden
    static frmQuarter = "2024Q1";
    static frmPort = 0;
    static frmSupplier = 0;
    static frmDateStr = "";
    static frmDate = "";

    //header short
    static hdPortName = "";
    static hdSupplierName = "";
    static hdVesselName = "";

    // filter
    static frmProId = 0;

    //result data
    static resMethod = "";
    static resBasePrice = "";
    static resCategory = "";
    static resDiscount = "";
    static resPPD = "";
    static resUnit = "";
    static resPrice = "";
    static resRiceNum = 0.0;
    static resError = "";  

    //modal data
    static modId = 0;
    static modName = "";
    static modPort = 0;
    static modSupplier = 0;
    static modMember = 0;
    static modVessel = 0;
    static modDate = "";

    // handle to handle
    static isCalc = false;

    constructor(props) {
        super(props);
        this.state = {
            ports: [], suppliers: [], products: [], curSup: 0, operators: [],
            loading: true, val: "", value: '', suggestions: [], headers: [],
            modal: false, page: 0, dets: [], vessels:[], curVes: 0, periods: []
        };
        this.handleCalc = this.handleCalc.bind(this);
        this.handleModal = this.handleModal.bind(this);
        //Proforma header
        this.handleFilter = this.handleFilter.bind(this);
        this.handleMemVessels = this.handleMemVessels.bind(this);
        // handle CRUD
        this.handleDetAdd = this.handleDetAdd.bind(this);
        this.handleHeadDel = this.handleHeadDel.bind(this);
        this.handleHeaders = this.handleHeaders.bind(this);
        this.handleDetDelete = this.handleDetDelete.bind(this);
        // export
        this.handleExport = this.handleExport.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderDets(dets, that, hDetDelete) {
        return (
            <div>
                <table id="sortTable" className='table table-striped' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Quantity</th>
                            <th>Pack.</th>
                            <th>Product</th>
                            <th>Unit</th>
                            <th>BPrice100</th>
                            <th>Dics.</th>
                            <th>PPD</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dets.map((det) =>
                            <tr key={det.pdetId}>
                                <td>{det.pdetQuantity}</td>
                                <td>{det.pdetPackaging}</td>
                                <td>{det.pdetProductName}</td>
                                <td>{det.pdetUnit}</td>
                                <td>{det.pdetBasePrice}</td>
                                <td>{det.pdetDiscount}</td>
                                <td>{det.pdetPpd}</td>
                                <td>{det.pdetPrice}</td>
                                <td>
                                    <Button color="danger"
                                        onClick={hDetDelete} value={det.pdetId} >
                                        Delete 
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }


    async handleCalc(event) {
        AdmPriceFind.isCalc = true;
        var ret = await this.doCalc();
        // if (!AdmPriceFind.isCalc) return;
        this.setState({ loading: false });
    }

    async handleHeaders(event) {
        let val = event.target.value;
        AdmPriceFind.frmProId = val;
        // set frmPort, frmSupplier, frmDate & curSup
        var myrec = this.state.headers.find(x => x.prohId === parseInt(val));
        AdmPriceFind.frmPort = myrec.prohPort;
        AdmPriceFind.frmSupplier = myrec.prohSupplier;
        AdmPriceFind.frmDate = myrec.prohDate;
        AdmPriceFind.hdPortName = this.state.ports.find(x => x.prtId === myrec.prohPort).prtName;
        AdmPriceFind.hdSupplierName = this.state.suppliers.find(x => x.supId === myrec.prohSupplier).supName;
        AdmPriceFind.hdVesselName = this.state.vessels.find(x => x.vesId === myrec.prohVessel).vesCurrentName;
        this.setState({ curSup: myrec.prohSupplier });
    }

    async handleExport(event) {
        // get list to export
        if (this.state.headers.length === 0) { alert("No List to Export"); return; }
        if (AdmPriceFind.frmProId == 0) AdmPriceFind.frmProId = this.state.headers[0].prohId;
        // send header id to server
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListExport/' + AdmPriceFind.frmProId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            // load file to client
            var res = "upload/proforma" + AdmPriceFind.frmProId + ".xlsx";
            window.open(res, '_blank', 'noreferrer');
            await sleep(1000);
            /*
            alert("Make sure that your file is uploaded\n" +
                "and Click OK to remove it from the server\n" +
                  "for security reasons");
            */
        }
        else alert("failed to export this list");
    }

    async doCalc() {
        if (AdmPriceFind.frmQuantity == null) AdmPriceFind.frmQuantity = 0;

        if (AdmPriceFind.frmPort == null) AdmPriceFind.frmPort = 0;
     
        if (AdmPriceFind.frmProduct == null) {
            alert("Please select a Product");
            AdmPriceFind.isCalc = false;
            return;
        }
        
        if ((AdmPriceFind.frmQuantity == "") || (AdmPriceFind.frmQuantity == 0)) {
            alert("Please insert a Quantity");
            AdmPriceFind.isCalc = false;
            return;
        }

        // get quarter

        var myDate = Date.parse(AdmPriceFind.frmDate);
        var formDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
            .format(myDate);


       
        var period = this.state.periods.find
            (x => ((Date.parse(x.perStart) <= myDate) && (Date.parse(x.perEnd) >= myDate)
                   && (x.perSupplier == AdmPriceFind.frmSupplier)     )
        );

        if (period == null) {
            alert("No prices for the selected date: " + formDate);
            AdmPriceFind.isCalc = false;
            return;
        }
        AdmPriceFind.frmQuarter = period.perName;

        const token = await authService.getAccessToken();
        var response = await fetch('mar1/getPrice', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    Supplier: AdmPriceFind.frmSupplier,
                    Quantity: AdmPriceFind.frmQuantity,
                    Pack: AdmPriceFind.frmPack,
                    Product: AdmPriceFind.frmProduct,
                    Port: AdmPriceFind.frmPort,
                    Period: AdmPriceFind.frmQuarter
                }
            )
        });

        if (!response.ok) {
            alert("Bad Input. Please correct");
            AdmPriceFind.isCalc = false;
            return;
        }

        var res = await response.json();

        console.log("res");
        console.log(res);

        AdmPriceFind.resMethod = res.resMethod;
        AdmPriceFind.resBasePrice = res.resBasePrice;
        AdmPriceFind.resCategory = res.resCategory;
        AdmPriceFind.resDiscount = res.resDiscount;
        AdmPriceFind.resPPD = res.resPPD;
        AdmPriceFind.resUnit = res.resUnit;
        AdmPriceFind.resPrice = res.resPrice;
        AdmPriceFind.resPriceNum = res.resPriceNum;
        if (res.resError != "ok") {
            alert(res.resError);
            AdmPriceFind.isCalc = false;
        }
    }

    handleFilter(event) {
        let val = event.target.value;
        AdmPriceFind.modSupplier = val;
        this.setState({ curSup: val });
    }

    handleMemVessels(event) {
        let val = event.target.value;
        AdmPriceFind.modMember = val;
        this.setState({ curVes: val });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmPriceFind.modId = 0;
                AdmPriceFind.modName = "";
                AdmPriceFind.modPort = 0;
                this.onChange(event, { newValue: "" });
                AdmPriceFind.modSupplier = 0;
                AdmPriceFind.modMember = 0;              
                AdmPriceFind.modVessel = 0;
                AdmPriceFind.modDate = "";
                this.setState({ modal: true, curVes: 0 });
            }
            else {
                if (this.state.headers.length === 0) {
                    alert("No Proformas found");
                    return;
                }
                if (AdmPriceFind.frmProId == 0) AdmPriceFind.frmProId = this.state.headers[0].prohId;
                val = AdmPriceFind.frmProId;
                let myrec = this.state.headers.find(x => x.prohId === parseInt(val));
                AdmPriceFind.modId = myrec.prohId;
                AdmPriceFind.modName = myrec.prohName;
                AdmPriceFind.modPort = myrec.prohPort;
                const portName = this.state.ports.find(x => x.prtId === myrec.prohPort).prtName;
                this.onChange(event, { newValue: portName });
                AdmPriceFind.modSupplier = myrec.prohSupplier;
                AdmPriceFind.modMember = myrec.prohMember;
                AdmPriceFind.modVessel = myrec.prohVessel;
                AdmPriceFind.modDate = myrec.prohDate;
                //const dd = date.slice(0, 2), dm = date.slice(3, 5), dy = date.slice(6, 10);
                //AdmPriceFind.modDate = dy+"-" + dm + "-" + dd;
                this.setState({ modal: true, curVes: myrec.prohMember });
            }
        }
        else {
            if (val !== "-1") {
                if (val !== "0") {
                    
                    if (window.confirm("This may delete other Suppliers items \n\r" +
                        "and may affect prices for all lines \n\r " +
                        "Are you sure? " ) === false) {
                        return;
                    }        
                    AdmPriceFind.frmProId = val;
                    const res = await AdmPriceFind.headUpdate();
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        this.setState({ modal: false });
                        return;
                    }

                    // header updated
                    // update dets

                    const token = await authService.getAccessToken();
                    var response = await fetch('mar1/getprice/' + AdmPriceFind.frmProId, {
                        method: 'PUT',
                        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
                    });

                    // alert("ProForma updated successfully");
                    // await sleep(500);
                    this.populateData();
                    this.setState({ modal: false });
                    return;
                    // let the database catch up
                    //    const token = await authService.getAccessToken();
                    //    const response = await fetch('mar1/proListHeads', {
                    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                    //    });
                    //    const dbheaders = await response.json();
                    //    this.setState({ headers: dbheaders, modal: false });
                    //    return;                        

                } else {
                    const res = await AdmPriceFind.headAdd(); 
                    if (res.result !== "ok") {
                        alert("Error: " + res.result);
                        return;
                    } else {
                        alert("Proforma created successfully");
                        await sleep(1000);
                        // let the database catch up
                        // this.populateData();
                         const token = await authService.getAccessToken();
                         const response = await fetch('mar1/proListHeads', {
                             headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                         });
                         const dbheaders = await response.json();
                         AdmPriceFind.frmProId = res.prohId;
                         this.setState({ headers: dbheaders, modal: false });
                        return;
                    }

                }
            }
            else {
                this.setState({ modal: false });
            }
        }
    }

    static async headAdd() {
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHeads', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    proUser: null,
                    ProhName: AdmPriceFind.modName,
                    ProhMember: AdmPriceFind.modMember,
                    prohSupplier: AdmPriceFind.modSupplier,
                    prohPort: AdmPriceFind.modPort,
                    ProhVessel: AdmPriceFind.modVessel,
                    ProhDate: AdmPriceFind.modDate
                }
            )
        });
        var res = await response.json();
        if (response.status === 201) // created
            return {result: "ok"};
        else {
            return {result: res}
        }
    }

    static async headUpdate() {
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListHeads/' + AdmPriceFind.frmProId, { 
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    ProhId: AdmPriceFind.frmProId,
                    ProhName: AdmPriceFind.modName,
                    ProhMember: AdmPriceFind.modMember,
                    prohSupplier: AdmPriceFind.modSupplier,
                    prohPort: AdmPriceFind.modPort,
                    ProhVessel: AdmPriceFind.modVessel,
                    ProhDate: AdmPriceFind.modDate
                }
            )
        });
        if (response.status == 204) // nocontent cannot be read by json
            return {result: "ok"};
        else {
            return {result: "error " + response.status}
        }
    }

    async handleDetAdd(event) {
        if (this.state.headers.length === 0) {
            alert("Please create a List"); return;
        }
        if (AdmPriceFind.frmProId == 0) AdmPriceFind.frmProId = this.state.headers[0].prohId;
        AdmPriceFind.isCalc = true;
        var ret = await this.doCalc();
        if (!AdmPriceFind.isCalc) {
            return;
        }


        var prod = this.state.products.find(x => x.prdId == AdmPriceFind.frmProduct);
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/proListDets', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    PdetHead: AdmPriceFind.frmProId,
                    PdetProduct : AdmPriceFind.frmProduct,
                    PdetQuantity: parseFloat(AdmPriceFind.frmQuantity),
                    PdetPackaging: AdmPriceFind.frmPack,
                    PdetPeriod: AdmPriceFind.frmQuarter,
                    PdetCategory: AdmPriceFind.resCategory,
                    PdetPrice: AdmPriceFind.resPrice,
                    PdetDiscount: AdmPriceFind.resDiscount,
                    PdetPpd: AdmPriceFind.resPPD,
                    PdetUnit: AdmPriceFind.resUnit,
                    PdetMethod: AdmPriceFind.resMethod,
                    PdetBasePrice: AdmPriceFind.resBasePrice,
                    PdetPriceNum: AdmPriceFind.resPriceNum,
                    PdetDate: AdmPriceFind.frmDate,
                    PdetUser: null,
                    PdetProductName: prod.prdRefCode
                }
            )
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }

    async handleHeadDel(event) {
        if (this.state.headers.length === 0) {
            alert("No List to Delete"); return;
        }
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        if (AdmPriceFind.frmProId == 0) AdmPriceFind.frmProId = this.state.headers[0].prohId;
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/ProListHeads/' + AdmPriceFind.frmProId, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const response4 = await fetch('mar1/proListHeads', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbheaders = await response4.json();
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        if (dbheaders.length > 0)
            AdmPriceFind.frmProId = dbheaders[0].prohId;
        else {
            AdmPriceFind.frmProId = 0;
            AdmPriceFind.frmDate = "";
            AdmPriceFind.hdPortName = "";
            AdmPriceFind.hdSupplierName = "";
            AdmPriceFind.hdVesselName = "";
        }
        this.setState({ headers: dbheaders, dets:dbdets });
    }

    async handleDetDelete(event) {
        let val = event.target.value;
        if (window.confirm("Are you sure you want to delete this item?") === false) {
            return;
        }
        const token = await authService.getAccessToken();
        var response = await fetch('mar1/ProListDets/' + val, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();
        this.setState({ dets: dbdets });
    }

    onChange = (event, { newValue }) => {
        // seek newvalue in portList
        console.log("looking for: " + newValue);
        var myport = this.state.ports.find(function (port) {
            return port.prtName.toLowerCase() == newValue.toLowerCase();
        });
        if (myport != null) {
            AdmPriceFind.modPort = myport.prtId;
        }
        else {
            AdmPriceFind.modPort = 0;
        }
        this.setState({
            value: newValue
        });
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps =
        {
            placeholder: 'Type a port',
            value,
            onChange: this.onChange
        };

        var ports = [];
        var products = [];
        var memVessels = [];
        var curSup = this.state.curSup;
        var curVes = this.state.curVes;
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        var lines = [];
        var total = 0;
        
        if (!this.state.loading) {
            ports = this.state.ports;
            AdmPriceFind.suppliers = this.state.suppliers;
            AdmPriceFind.allproducts = this.state.products;
            products = AdmPriceFind.allproducts.filter(function (product) {
                return product.prdSupplier == curSup;
            });
            memVessels = this.state.vessels.filter(function (vessel) {
                return vessel.vesOperator == curVes;
            });
            if (AdmPriceFind.frmProduct == null)
                AdmPriceFind.frmProduct = products[0].prdId;
            if (AdmPriceFind.frmSupplier == null)
                AdmPriceFind.frmSupplier = AdmPriceFind.suppliers[0].supId;
            portList = [];
            ports.forEach((port) => {
                portList.push({ name: port.prtName, id: port.ptrId });
            });
            // filter
            lines = this.state.dets;
            if ((this.state.headers.length > 0) && (AdmPriceFind.frmProId == 0))
                AdmPriceFind.frmProId = this.state.headers[0].prohId;
            lines = lines.filter(function (line) {
                return line.pdetHead == AdmPriceFind.frmProId;
            });
            for (let i = 0; i < lines.length; i++) {
                total += lines[i].pdetPriceNum;
            }
            total = total.toFixed(2);
            //pages
            const sorted = lines;
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                lines = sorted.slice((page) * pageSize, (page + 1) * pageSize);
            } else {
                lines = sorted;
            }
        }

        const contents1 = this.state.loading
            ? <p><em>...loading </em></p>
            : AdmPriceFind.renderDets(lines, this.state, this.handleDetDelete);

        if ((!this.state.loading) && (pages > 1)) {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc + 1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                <PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");

        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2">
                            <h2 id="tabelLabel" > Find Prices
                            </h2>
                        </div>
                        <div class="col-lg-4">
                            <select style={{
                                "width": "100%", "height": "37px",
                                "margin-top": "7px", "background": "aliceblue" }}
                                onChange={this.handleHeaders}
                                defaultValue={AdmPriceFind.frmProId}
                                id="frmProId">
                                {this.state.headers.map((head) =>
                                    <option value={head.prohId}
                                    >{head.prohName}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={-1}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                New Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="info" onClick={this.handleModal} value={AdmPriceFind.frmProId}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Edit Header
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="danger" onClick={this.handleHeadDel}
                                style={{ "width": "100%", "margin-top": "7px" }}>
                                Delete Header
                            </Button>
                        </div>
                    </div>

                    <div class="row" style={{ "background": "beige", "margin-top": "10px" }}>
                        <div class="col-lg-2">
                            Supplier: <b>{AdmPriceFind.hdSupplierName}</b>
                        </div>
                        <div class="col-lg-4">
                            Port: <b>{AdmPriceFind.hdPortName}</b>
                        </div>
                        <div class="col-lg-2">
                            Date: <b>{AdmPriceFind.frmDate}</b>
                        </div>
                        <div class="col-lg-4">
                            Vessel: <b>{AdmPriceFind.hdVesselName.substring(0,
                                Math.min(AdmPriceFind.hdVesselName.length, 26))}</b>
                        </div>

                    </div>

                    <div class="row" style={{ "margin-top": "5px" }}>
                        <div class="col-lg-4">
                            <Label><b>Product</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => AdmPriceFind.frmProduct = e.target.value}>
                                id = "frmproduct"
                                {products.map((product) =>
                                    <option value={product.prdId}
                                    >{product.prdRefCode}</option>)}
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Packaging</b></Label>
                            <select style={{ "width": "100%", "height": "35px", "background-color": "#e9ecef" }}
                                onChange={(e) => AdmPriceFind.frmPack = e.target.value}>
                                <option value="Bulk">Bulk</option>
                                <option value="Ibc">IBC</option>
                                <option value="Drum">Drum</option>
                                <option value="Pail">Pail</option>
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <Label><b>Quantity</b></Label>
                            <Input onChange={(e) => AdmPriceFind.frmQuantity = e.target.value}
                                id="frmQuantity"
                                type="text" defaultValue = "0"
                                style={{ "background-color": "#e9ecef", "text-align":"right" }}
                            />
                        </div>
                        <div class="col-lg-2">
                            <Button color="success" onClick={this.handleDetAdd }
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Add Line
                            </Button>
                        </div>
                        <div class="col-lg-2">
                            <Button color="warning" onClick={this.handleExport} value={AdmPriceFind.frmProId}
                                style={{ "width": "100%", "margin-top": "30px" }}>
                                Excel Export
                            </Button>
                        </div>
                    </div>
                </div>
                <br />
                {contents1}

                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                        </div>
                        <div class="col-lg-2">
                            Lines: <b>{lines.length}</b>
                        </div>
                        <div class="col-lg-4">
                            Total: $ <b>{total}</b>
                        </div>
                    </div>
                </div>


                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages - 1, page + 1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal}>
                    <ModalHeader>Proforma</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Label for="modName" style={{ "padding-top": "15px" }}>
                            <b>Name</b>
                            </Label>
                            <Input onChange={(e) => AdmPriceFind.modName = e.target.value}
                                id="modName"
                                type="text"
                                defaultValue={AdmPriceFind.modName}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Port</b></Label>
                            <Autosuggest
                                id="modPort"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                            <Label style={{ "padding-top": "15px" }}>
                                <b>Supplier</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={this.handleFilter}
                                id = "modSupplier"
                                defaultValue={AdmPriceFind.modSupplier}>
                                <option value={0}></option>
                                {AdmPriceFind.suppliers.map((supplier) =>
                                    <option value={supplier.supId}
                                    >{supplier.supName}</option>)}
                            </select>
                            <Label for="modMember" style={{ "padding-top": "15px" }}>
                                <b>Member</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={this.handleMemVessels}
                                id="modMember"
                                defaultValue={AdmPriceFind.modMember}>
                                <option value={0}></option>
                                {this.state.operators.map((operator) =>
                                    <option value={operator.oprId}>{operator.oprName}</option>)}
                            </select>
                            <Label for="modVessel" style={{ "padding-top": "15px" }}>
                                <b>Vessel</b>
                            </Label>
                            <select style={{ "width": "100%", "height": "35px" }}
                                onChange={(e) => AdmPriceFind.modVessel = e.target.value}
                                id="modVessel"
                                defaultValue={AdmPriceFind.modVessel}>
                                <option value={0}></option>
                                {memVessels.map((vessel) =>
                                    <option value={vessel.vesId}>{vessel.vesCurrentName}</option>)}
                            </select>
                            <Label for="modDate" style={{ "padding-top": "15px" }}>
                                <b>Date</b>
                            </Label>
                            <Input onChange={(e) => AdmPriceFind.modDate = e.target.value}
                                id="DateFrom" type="date"
                                dateformat= 'DD/M/YYYY'
                                style={{
                                    "height": "35px",
                                    "background-color": "#e9ecef"
                                }} defaultValue={AdmPriceFind.modDate}
                            />
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleModal} value={AdmPriceFind.modId}>
                            {(AdmPriceFind.modId === 0) ? "Add New" : "Apply Changes"}
                            </Button>{' '}
                        <Button color="secondary" onClick={this.handleModal} value={-1}
                            style={{ "width" : "100px"} }
                        >
                            Exit
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async dataGet() {
        console.log("getdata");
        const token = await authService.getAccessToken();

        const response1 = await fetch('mar1/suppliers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response1.json();

        const response2 = await fetch('mar1/ports', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbports = await response2.json();

        const response3 = await fetch('mar1/products', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbproducts = await response3.json();

        const response4 = await fetch('mar1/proListHeads', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
          });
        const dbheaders = await response4.json();

         const response5 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response5.json();

        const response6 = await fetch('mar1/proListDets', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbdets = await response6.json();

        const response7 = await fetch('mar1/vessels', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbvessels = await response7.json();

        const response8 = await fetch('mar1/periods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbperiods = await response8.json();


        return {
            ports: dbports, suppliers: dbsuppliers, products: dbproducts,
            headers: dbheaders, operators: dboperators, dets: dbdets, vessels: dbvessels,
            periods: dbperiods
        };
    }

    async populateData() {
        const data = await AdmPriceFind.dataGet();
        const dbsuppliers = data.suppliers;
        const dbports = data.ports;
        const dbproducts = data.products;
        const dbheaders = data.headers;
        const dbdets = data.dets;
        const dboperators = data.operators;
        const dbvessels = data.vessels;
        const dbperiods = data.periods;
        var vcurSup = 1;
        if (dbheaders.length > 0) {
            AdmPriceFind.frmProId = dbheaders[0].prohId;
            AdmPriceFind.frmPort = dbheaders[0].prohPort;
            AdmPriceFind.frmSupplier = dbheaders[0].prohSupplier;
            AdmPriceFind.frmDate = dbheaders[0].prohDate;
            AdmPriceFind.hdPortName = dbports.find(x => x.prtId === dbheaders[0].prohPort).prtName;
            AdmPriceFind.hdSupplierName = dbsuppliers.find(x => x.supId === dbheaders[0].prohSupplier).supName;
            AdmPriceFind.hdVesselName = dbvessels.find(x => x.vesId === dbheaders[0].prohVessel).vesCurrentName;
            vcurSup = dbheaders[0].prohSupplier
        }
       this.setState({
            ports: dbports, suppliers: dbsuppliers, products: dbproducts, curSup: vcurSup, operators: dboperators,
            loading: false, headers: dbheaders, dets: dbdets, vessels: dbvessels, curVes: 0, periods: dbperiods
        });
    }
}


